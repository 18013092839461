import { Card, CardActionArea, Grid2 } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { NavLink } from "react-router";

import { AppRoutes } from "../../Router";
import { TicketTypePill } from "../../components/shared/TicketTypePill";
import { CommentList } from "../components/CommentList";
import { CleaningPercentageChip } from "../components/equipment/CleaningPercentageChip";
import { DimmingsCountChip } from "../components/equipment/DimmingsCountChip";
import { EquipmentDescription } from "../components/equipment/EquipmentDescription";
import { EquipmentGroupChip } from "../components/equipment/EquipmentGroupChip";
import { EquipmentLocation } from "../components/equipment/EquipmentLocation";
import { EquipmentNumber } from "../components/equipment/EquipmentNumber";
import { MouldCloseFlowStatusChip } from "../components/mould/MouldCloseFlowStatusChip";
import { CodingChip } from "../components/ticket/CodingChip";
import { CreatedDateTime } from "../components/ticket/CreatedDateTime";
import { PriorityPill } from "../components/ticket/PriorityPill";
import { RequiredDates } from "../components/ticket/RequiredDates";
import { TicketNumber } from "../components/ticket/TicketNumber";
import { skeletonify, skeletonify_multiple } from "../skeleton";

import { TicketAssignStatus } from "./TicketAssignStatus";
import { TicketCard_Chips_ticket$key } from "./__generated__/TicketCard_Chips_ticket.graphql";
import { TicketCard_ticket$key } from "./__generated__/TicketCard_ticket.graphql";

const ChipsActualComponent = (props: { ticket: TicketCard_Chips_ticket$key }) => {
  const { ticket: ticketRef } = props;
  const ticket = useFragment(
    graphql`
      fragment TicketCard_Chips_ticket on Ticket {
        equipment {
          ...DimmingsCountChip_equipment
          ...CleaningPercentageChip_equipment
          ...EquipmentGroupChip_equipment
        }
        ...CodingChip_ticket
      }
    `,
    ticketRef,
  );

  return [
    <EquipmentGroupChip key="EquipmentGroupChip" equipment={ticket.equipment} />,
    <CodingChip key="Coding" ticket={ticket} />,
    <DimmingsCountChip key="DimmingsCount" equipment={ticket.equipment} />,
    <CleaningPercentageChip key="CleaningPercentage" equipment={ticket.equipment} />,
  ];
};

const Chips = skeletonify(
  "TicketCard_Chips",
  ChipsActualComponent,
  () => [
    <EquipmentGroupChip.Skeleton key="EquipmentGroupChip" />,
    <CodingChip.Skeleton key="Coding" />,
    <DimmingsCountChip.Skeleton key="DimmingsCount" />,
    <CleaningPercentageChip.Skeleton key="CleaningPercentage" />,
  ],
  ({ children }) => (
    <Grid2 container spacing={2} direction="row" alignItems="center">
      {children.map((c) => (
        <Grid2 key={c.key}>{c}</Grid2>
      ))}
    </Grid2>
  ),
);

const TicketCardActual = (props: { ticket: TicketCard_ticket$key }) => {
  const { ticket: ticketRef } = props;
  const ticket = useFragment(
    graphql`
      fragment TicketCard_ticket on Ticket {
        ticketNumber
        equipment {
          ...EquipmentNumber_equipment
          ...EquipmentLocation_equipment #@defer
          ...EquipmentDescription_equipment
          ...MouldCloseFlowStatusChip_mould
        }
        errorDescription {
          ...CommentList_comment @arguments(includeTimestamp: false)
        }
        ...TicketTypePill_ticket
        ...TicketNumber_ticket
        ...PriorityPill_ticket
        ...TicketCard_Chips_ticket #@defer(label: "chips")
        ...RequiredDates_ticket
        ...TicketAssignStatus_ticket
        ...CreatedDateTime_ticket
      }
    `,
    ticketRef,
  );

  const { ticketNumber, equipment, errorDescription } = ticket;

  return {
    routeTo: ticketNumber ? AppRoutes.tickets.byTicketNumber(ticketNumber, "details") : undefined,
    priority: <PriorityPill ticket={ticket} />,
    equipmentNumber: <EquipmentNumber equipment={equipment} />,
    ticketNumber: <TicketNumber ticket={ticket} />,
    equipmentDescription: <EquipmentDescription equipment={equipment} />,
    errorDescription: <CommentList comments={errorDescription} />,
    chips: <Chips.Suspense ticket={ticket} />,
    ticketType: <TicketTypePill ticket={ticket} />,
    requiredDates: <RequiredDates ticket={ticket} />,
    equipmentLocation: <EquipmentLocation.Suspense equipment={equipment} />,
    assignStatus: <TicketAssignStatus ticket={ticket} />,
    mouldCloseFlowStatusChip: <MouldCloseFlowStatusChip mould={equipment} />,
    createdDateTime: <CreatedDateTime ticket={ticket} />,
  };
};

const TicketCardSkeleton = () => ({
  priority: <PriorityPill.Skeleton />,
  equipmentNumber: <EquipmentNumber.Skeleton />,
  ticketNumber: <TicketNumber.Skeleton />,
  equipmentDescription: <EquipmentDescription.Skeleton />,
  errorDescription: <CommentList.Skeleton />,
  chips: <Chips.Skeleton />,
  requiredDates: <RequiredDates.Skeleton />,
  equipmentLocation: <EquipmentLocation.Skeleton />,
  assignStatus: <TicketAssignStatus.Skeleton />,
  mouldCloseFlowStatusChip: <MouldCloseFlowStatusChip.Skeleton />,
  createdDateTime: <CreatedDateTime.Skeleton />,
});

const TicketCardStructure = (props: {
  routeTo?: string;
  priority: ReactElement;
  equipmentNumber: ReactElement;
  ticketNumber: ReactElement;
  equipmentDescription: ReactElement;
  errorDescription: ReactElement;
  chips: ReactElement;
  requiredDates: ReactElement;
  ticketType?: ReactElement;
  equipmentLocation: ReactElement;
  assignStatus: ReactElement;
  mouldCloseFlowStatusChip: ReactElement;
  createdDateTime: ReactElement;
}) => {
  const {
    routeTo,
    priority,
    equipmentNumber,
    ticketNumber,
    equipmentDescription,
    errorDescription,
    chips,
    ticketType,
    requiredDates,
    equipmentLocation,
    assignStatus,
    mouldCloseFlowStatusChip,
    createdDateTime,
  } = props;
  return (
    <Card sx={{ flex: 1, p: 0 }}>
      <CardActionArea component={NavLink} to={routeTo ?? ""} disabled={!routeTo} sx={{ p: 2 }}>
        <Grid2 container spacing={3} direction="row">
          <Grid2 minWidth={102} ml={2}>
            <Grid2 container direction="column" spacing={1}>
              <Grid2>{priority}</Grid2>
              <Grid2>{assignStatus}</Grid2>
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: "grow" }}>
            <Grid2 container direction="column" spacing={1}>
              <Grid2 container spacing={1} direction="row" alignItems="center">
                <Grid2>{equipmentNumber}</Grid2>
                <Grid2>{ticketNumber}</Grid2>
              </Grid2>
              <Grid2>{equipmentDescription}</Grid2>
              <Grid2>{createdDateTime}</Grid2>
              <Grid2>{errorDescription}</Grid2>
              <Grid2>{chips}</Grid2>
            </Grid2>
          </Grid2>
          <Grid2>
            <Grid2
              container
              direction="column"
              spacing={2}
              minHeight={"100%"}
              justifyContent={"space-between"}
              alignItems="flex-end"
            >
              <Grid2>
                <Grid2
                  container
                  direction="row"
                  spacing={3}
                  minHeight={"100%"}
                  justifyContent={"end"}
                  alignItems="flex-start"
                >
                  <Grid2 justifyContent="flex-end">{mouldCloseFlowStatusChip}</Grid2>
                  <Grid2>
                    <Grid2 container spacing={1}>
                      <Grid2>{ticketType}</Grid2>
                      <Grid2>{requiredDates}</Grid2>
                    </Grid2>
                  </Grid2>
                </Grid2>
                <Grid2>{equipmentLocation}</Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </CardActionArea>
    </Card>
  );
};

export const TicketCard = skeletonify_multiple("TicketCard", TicketCardActual, TicketCardSkeleton, TicketCardStructure);
