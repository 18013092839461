import { Grid2 } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { JSX } from "react";

import { skeletonify_multiple } from "../skeleton";
import { DetailsPaneWrapper, FullWidthGrid } from "../ticket-details/TicketDetailsPane";

import { QAOperations } from "./QAOperations";
import { QATestStatus } from "./QATestStatus";
import { QADetailsPane_equipment$key } from "./__generated__/QADetailsPane_equipment.graphql";

const ActualComponent = ({ equipment: equipmentRef }: { equipment?: QADetailsPane_equipment$key }) => {
  const equipment = useFragment(
    graphql`
      fragment QADetailsPane_equipment on Equipment {
        __typename
        ...QATestStatus_equipment #@defer
        ...QAOperations_equipment #@defer
      }
    `,
    equipmentRef ?? null,
  );
  const qaTestStatus = equipment ? <QATestStatus equipment={equipment} /> : <></>;
  const qaOperations = equipment ? <QAOperations equipment={equipment} /> : <></>;

  return { qaTestStatus, qaOperations };
};

const SkeletonComponent = () => ({
  qaTestStatus: <QATestStatus.Skeleton />,
  qaOperations: <QAOperations.Skeleton />,
});
const StructureComponent = (props: { qaTestStatus: JSX.Element; qaOperations: JSX.Element }) => {
  const { qaTestStatus, qaOperations } = props;
  return (
    <DetailsPaneWrapper>
      <Grid2 container direction="column" spacing={2}>
        <FullWidthGrid>{qaTestStatus}</FullWidthGrid>

        <FullWidthGrid>{qaOperations}</FullWidthGrid>
      </Grid2>
    </DetailsPaneWrapper>
  );
};

export const QADetailsPane = skeletonify_multiple(
  "QADetailsPane",
  ActualComponent,
  SkeletonComponent,
  StructureComponent,
);
