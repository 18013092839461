import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { Container, Grid2 } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, ReactElement, ReactNode } from "react";
import { useFragment } from "react-relay";

import { GetTicketDetailsQuery, GetTicketDetailsQueryVariables } from "../../__apollo__/graphql";
import { useGMQuery } from "../../apollo/customApolloHooks";
import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from "../../components/add-time/overview/time-registration-gql-fragments";
import { TD_ASSIGNED_TO_FRAGMENT } from "../../components/ticket-details/TDAssignedTo";
import { TD_CURRENT_LOCATION } from "../../components/ticket-details/TDCurrentLocation";
import { TD_ERROR_DESCRIPTION_FRAGMENT } from "../../components/ticket-details/TDErrorDescription";
import { TD_REPAIR_DOCUMENTATION } from "../../components/ticket-details/TDRepairFragments";
import { TypeChecked, useTicketQueryTypeCheck } from "../../containers/TicketByIdTypecheckHook";
import { PERMISSIONS_CAN_CLOSE_FRAGMENT } from "../../utility/permissions/ticket";
import { ManufacturerWidget } from "../components/equipment/ManufacturerWidget";
import { WarrantyWidget } from "../components/equipment/WarrantyWidget";
import { skeletonify_multiple } from "../skeleton";

import { TicketDetailsPane_ticket$key } from "./__generated__/TicketDetailsPane_ticket.graphql";
import { TDAssignedTo } from "./widgets/TDAssignedTo";
import { TDCreatedBy } from "./widgets/TDCreatedBy";
import { TDCurrentLocation, TDCurrentLocationSkeleton } from "./widgets/TDCurrentLocation";
import { TDDimmingDetails } from "./widgets/TDDimmingDetails";
import { TDDqpData } from "./widgets/TDDqpData";
import { TDErrorDescription } from "./widgets/TDErrorDescription";
import { TDMachineHours } from "./widgets/TDMachineHours";
import { TDManhours } from "./widgets/TDManhours";
import { TDMouldDetails } from "./widgets/TDMouldDetails";
import { TDMouldStatus } from "./widgets/TDMouldStatus";
import { TDRepairDescription } from "./widgets/TDRepairDescription";
import { TDSpringChange } from "./widgets/TDSpringChange";
import { TDSysCleaning } from "./widgets/TDSysCleaning";
import { TDWidgetWithCollapsibleRowsSkeleton } from "./widgets/TDWidgetWithCollapsibleRowsSkeleton";

export const TICKET_DETAILS_FRAGMENT = gql`
  fragment TicketDetails on Ticket {
    id
    notificationType
    ...AssignedTo
    ...TicketDetailsErrorDescription
    ...TicketDetailsRepairDescriptions
    ...TimeRegistrationOverview
    ...CanCloseMouldGreenTicket
    equipment {
      ... on EquipmentValue {
        value {
          id
          ...TicketDetailsLocation
        }
      }
    }
  }
  ${TD_ASSIGNED_TO_FRAGMENT}
  ${TD_CURRENT_LOCATION}
  ${TD_ERROR_DESCRIPTION_FRAGMENT}
  ${TD_REPAIR_DOCUMENTATION}
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
  ${PERMISSIONS_CAN_CLOSE_FRAGMENT}
`;

export const TICKET_DETAILS_QUERY = gql`
  query GetTicketDetails($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        notificationType
        ...TicketDetails
      }
    }
  }
  ${TICKET_DETAILS_FRAGMENT}
`;

const ActualComponent = ({ ticket: ticketRef }: { ticket?: TicketDetailsPane_ticket$key }) => {
  const ticket = useFragment(
    graphql`
      fragment TicketDetailsPane_ticket on Ticket {
        __typename
        ticketNumber
        ...TDCreatedBy_ticket #@defer
        ...TDErrorDescription_ticket #@defer
        equipment {
          __typename
          equipmentNumber
          ...TDMouldDetails_equipment #@defer
          ...TDMouldStatus_equipment #@defer
          ...TDDimmingDetails_mould #@defer
          ...ManufacturerWidget_equipment #@defer
          ...WarrantyWidget_equipment #@defer
          ...TDSysCleaning_mould #@defer
          ...TDSpringChange_mould #@defer
          ...TDDqpData_Mould #@defer
          ... on Mould {
            springChangeInterval
            cleaningInterval
          }
        }
      }
    `,
    ticketRef ?? null,
  );

  const ticketNumber = ticket?.ticketNumber?.toString();
  const equipmentNumber = ticket?.equipment?.equipmentNumber?.toString();

  return {
    createdBy: <TDCreatedBy ticket={ticket} />,
    assignedTo: hasValue(ticketNumber) ? <TDAssignedTo ticketNumber={ticketNumber} /> : undefined,
    mouldDetails:
      ticket?.equipment?.__typename === "Mould" ? <TDMouldDetails equipment={ticket?.equipment} /> : undefined,
    mouldStatus:
      ticket?.equipment?.__typename === "Mould" ? <TDMouldStatus equipment={ticket?.equipment} /> : undefined,
    dimmingDetails:
      ticket?.equipment?.__typename === "Mould" ? (
        <TDDimmingDetails mould={ticket.equipment} canEditDimmings={ticket.__typename !== "CMSTicket"} />
      ) : undefined,
    currentLocation: hasValue(equipmentNumber) ? <TDCurrentLocation equipmentNumber={equipmentNumber} /> : undefined,
    errorDescription: hasValue(ticket) ? <TDErrorDescription ticket={ticket} /> : undefined,
    repairDescription:
      hasValue(ticketNumber) && ticket?.__typename !== "CMSTicket" ? (
        <TDRepairDescription ticketNumber={ticketNumber} />
      ) : undefined,
    manhours: hasValue(ticketNumber) ? <TDManhours ticketNumber={ticketNumber} /> : undefined,
    machineHours: hasValue(ticketNumber) ? <TDMachineHours ticketNumber={ticketNumber} /> : undefined,
    warranty: <WarrantyWidget equipment={ticket?.equipment ?? null} />,
    manufacturer: <ManufacturerWidget equipment={ticket?.equipment ?? null} />,
    sysCleaning:
      ticket?.equipment?.__typename === "Mould" && ticket?.equipment?.cleaningInterval !== 0 ? (
        <TDSysCleaning equipment={ticket?.equipment} />
      ) : undefined,
    springChange:
      ticket?.equipment?.__typename === "Mould" && ticket?.equipment?.springChangeInterval !== 0 ? (
        <TDSpringChange equipment={ticket?.equipment} />
      ) : undefined,
    dqpData: ticket?.equipment?.__typename === "Mould" ? <TDDqpData equipment={ticket?.equipment} /> : undefined,
  };
};
const SkeletonComponent = () => ({
  createdBy: <TDCreatedBy.Skeleton />,
  assignedTo: <TDCreatedBy.Skeleton />,
  mouldDetails: <TDMouldDetails.Skeleton />,
  mouldStatus: <TDMouldStatus.Skeleton />,
  dimmingDetails: <TDDimmingDetails.Skeleton />,
  currentLocation: <TDCurrentLocationSkeleton />,
  errorDescription: <TDErrorDescription.Skeleton />,
  repairDescription: <TDWidgetWithCollapsibleRowsSkeleton />,
  manhours: <TDWidgetWithCollapsibleRowsSkeleton />,
  machineHours: <TDWidgetWithCollapsibleRowsSkeleton />,
  warranty: <WarrantyWidget.Skeleton />,
  manufacturer: <ManufacturerWidget.Skeleton />,
});
const StructureComponent = (props: {
  createdBy: ReactElement;
  assignedTo?: ReactElement;
  warranty?: ReactElement;
  manufacturer?: ReactElement;
  mouldDetails?: ReactElement;
  mouldStatus?: ReactElement;
  springChange?: ReactElement;
  sysCleaning?: ReactElement;
  dimmingDetails?: ReactElement;
  currentLocation?: ReactElement;
  errorDescription?: ReactElement;
  repairDescription?: ReactElement;
  manhours?: ReactElement;
  machineHours?: ReactElement;
  dqpData?: ReactElement;
}) => {
  const {
    assignedTo,
    createdBy,
    currentLocation,
    dimmingDetails,
    errorDescription,
    manhours,
    mouldDetails,
    mouldStatus,
    sysCleaning,
    springChange,
    repairDescription,
    warranty,
    manufacturer,
    machineHours,
    dqpData,
  } = props;

  return (
    <DetailsPaneWrapper>
      <Grid2 container direction="column" spacing={2} data-cy="TicketDetailsContainer">
        <Grid2 container direction="row" spacing={2}>
          <Grid2 container size={{ xs: 12, lg: 6 }} maxHeight={120}>
            {/* Max height is a hack to prevent blank spaces from flex layout black magic */}
            {createdBy}
          </Grid2>
          <Grid2 container size={{ xs: 12, lg: 6 }} maxHeight={120}>
            {/* Max height is a hack to prevent blank spaces from flex layout black magic */}
            {assignedTo}
          </Grid2>
        </Grid2>
        {warranty}
        {manufacturer}
        {mouldDetails && <FullWidthGrid>{mouldDetails}</FullWidthGrid>}
        {mouldStatus && <FullWidthGrid>{mouldStatus}</FullWidthGrid>}
        {dqpData && <FullWidthGrid>{dqpData}</FullWidthGrid>}
        {sysCleaning && <FullWidthGrid>{sysCleaning}</FullWidthGrid>}
        {springChange && <FullWidthGrid>{springChange}</FullWidthGrid>}
        {currentLocation && <FullWidthGrid>{currentLocation}</FullWidthGrid>}
        {errorDescription && <FullWidthGrid>{errorDescription}</FullWidthGrid>}
        {dimmingDetails && <FullWidthGrid>{dimmingDetails}</FullWidthGrid>}
        {repairDescription && <FullWidthGrid>{repairDescription}</FullWidthGrid>}
        {manhours && <FullWidthGrid>{manhours}</FullWidthGrid>}
        {machineHours && <FullWidthGrid>{machineHours}</FullWidthGrid>}
      </Grid2>
    </DetailsPaneWrapper>
  );
};

export const TicketDetailsPane = skeletonify_multiple(
  "TicketDetailsPane",
  ActualComponent,
  SkeletonComponent,
  StructureComponent,
);

export const DetailsPaneWrapper: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Container maxWidth="xl" sx={{ mb: 20 }}>
      {children}
    </Container>
  );
};

export const FullWidthGrid: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <Grid2 container size={{ xs: "grow" }}>
      {children}
    </Grid2>
  );
};

export const useApolloTicketDetailsQuery = (
  id?: string,
): TypeChecked<Extract<GetTicketDetailsQuery["ticket"], { __typename?: "Ticket" }>> | undefined => {
  const { data, loading } = useGMQuery<GetTicketDetailsQuery, GetTicketDetailsQueryVariables>(TICKET_DETAILS_QUERY, {
    skip: !hasValue(id),
    variables: { input: { id: id ?? "" } },
    fetchPolicy: "cache-and-network",
  });

  const checked = useTicketQueryTypeCheck<Extract<GetTicketDetailsQuery["ticket"], { __typename?: "Ticket" }>>({
    data,
    loading,
  });

  const checkedIsData = checked.type === "data";

  return checkedIsData ? checked : undefined;
};
