import { ReactNode, createContext, useEffect, useState, useContext, useRef, ReactElement } from "react";

import { useTranslation } from "../utility/i18n/translation";

import { useAuthContext } from "./AuthContext";

declare const window: Window & { onUsersnapLoad?: (api: UsersnapApi) => void };

type InitOptions = {
  user?: Record<string, unknown>;
  locale?: string;
  useSystemFonts?: boolean;
  useLocalStorage?: boolean;
  nativeScreenshot?: boolean;
  collectGeoLocation?: "all" | "none";
};

type UsersnapApi = {
  init: (options: InitOptions) => Promise<void>;
  destroy: () => Promise<void>;
  logEvent: (eventName: string) => Promise<void>;
  on: (eventName: string, callback: (event: unknown) => void) => void;
  off: (eventName: string, callback: (event: unknown) => void) => void;
};

type UsersnapContext = {
  usersnapApi: UsersnapApi | null;
};

export const UsersnapContext = createContext<UsersnapContext>({
  usersnapApi: null,
});

export type UsersnapContextProps = {
  children: ReactNode;
};

export const UsersnapProvider = (props: UsersnapContextProps): ReactElement => {
  const [usersnapApi, setUsersnapApi] = useState<UsersnapApi | null>(null);
  const { authenticated, currentUserId, email } = useAuthContext();
  const { locale } = useTranslation();
  const script = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    window.onUsersnapLoad = function (api: UsersnapApi) {
      api
        .init({
          locale,
          useSystemFonts: true,
          user: { email, userId: currentUserId },
        })
        .then(() => {
          setUsersnapApi(api);
        });
    };

    if (!script.current && authenticated && process.env.REACT_APP_USERSNAP_API_KEY) {
      script.current = document.createElement("script");
      script.current.defer = true;
      script.current.src = `https://widget.usersnap.com/global/load/${process.env.REACT_APP_USERSNAP_API_KEY}?onload=onUsersnapLoad`;
      document.head.appendChild(script.current);
    }

    return () => {
      setUsersnapApi((usersnapApi) => {
        (async () => {
          if (usersnapApi) {
            await usersnapApi.destroy();
            script.current?.remove();
            script.current = null;
            setUsersnapApi(null);
          }
        })();
        return usersnapApi;
      });
    };
  }, [authenticated, locale, currentUserId, email]);

  return <UsersnapContext.Provider value={{ usersnapApi }}>{props.children}</UsersnapContext.Provider>;
};

export const useUsersnapApi = (): UsersnapContext => {
  return useContext(UsersnapContext) as UsersnapContext;
};
