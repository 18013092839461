import { FC, ReactElement, ReactNode, Suspense } from "react";

type SkeletonExposingComponent<
  Props extends Record<string, unknown>,
  SkeletonProps extends Record<string, unknown>,
> = FC<Props> & {
  Skeleton: FC<SkeletonProps>;
  Suspense: FC<Props & SkeletonProps>;
};

/**
 * Factory for component with suspense and skeleton variants
 *
 * @param displayName The name of the component
 * @param actual The data for the actual component
 * @param skeleton A skeleton representation of `actual`
 * @param Structure The structure of the component, receives the return value of `actual` or `skeleton`
 */
export function skeletonify<
  Props extends Record<string, unknown>,
  SkeletonProps extends Record<string, unknown>,
  ComponentStructure extends ReactNode | Promise<ReactNode> | ReactElement,
>(
  displayName: string,
  actual: (props: Props) => ComponentStructure,
  skeleton: (props: SkeletonProps) => ComponentStructure,
  Structure: FC<{ children: ComponentStructure }> = ({ children }) => children,
): SkeletonExposingComponent<Props, SkeletonProps> {
  const Base: SkeletonExposingComponent<Props, SkeletonProps> = (props) => {
    const render = actual(props);
    return <Structure>{render}</Structure>;
  };

  Base.Skeleton = (props) => {
    const render = skeleton(props);
    return <Structure>{render}</Structure>;
  };

  Base.Suspense = (props) => {
    return (
      <Suspense fallback={<Base.Skeleton {...props} />}>
        <Base {...props} />
      </Suspense>
    );
  };

  Base.displayName = displayName;
  Base.Skeleton.displayName = `${displayName}Skeleton`;
  Base.Suspense.displayName = `${displayName}Suspense`;

  return Base;
}

/**
 * Factory for multiple components with suspense and skeleton variants
 *
 * @param displayName The name of the component
 * @param actual The data for the actual component
 * @param skeleton A skeleton representation of `actual`
 * @param Structure The structure of the component, receives the return value of `actual` or `skeleton`
 */
export function skeletonify_multiple<
  Props extends Record<string, unknown>,
  SkeletonProps extends Record<string, unknown>,
  ComponentStructure extends Record<string, unknown>,
>(
  displayName: string,
  actual: (props: Props) => ComponentStructure,
  skeleton: (props: SkeletonProps) => Record<string, unknown>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Structure: FC<any> = ({ children }) => children,
): SkeletonExposingComponent<Props, SkeletonProps> {
  const Base: SkeletonExposingComponent<Props, SkeletonProps> = (props) => {
    const render = actual(props);
    return <Structure {...render} />;
  };

  Base.Skeleton = (props) => {
    const render = skeleton(props);
    return <Structure {...render} />;
  };

  Base.Suspense = (props) => {
    return (
      <Suspense fallback={<Base.Skeleton {...props} />}>
        <Base {...props} />
      </Suspense>
    );
  };

  Base.displayName = displayName;
  Base.Skeleton.displayName = `${displayName}Skeleton`;
  Base.Suspense.displayName = `${displayName}Suspense`;

  return Base;
}
