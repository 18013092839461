import { hasValue } from "@lego/mst-error-utilities";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Grid2, IconButton, InputAdornment, TextField, TextFieldProps, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import type { DebouncedFunc } from "lodash";
import { FC, JSX, useCallback, useEffect, useRef, useState, useTransition } from "react";
import { Disposable, RefetchFnDynamic, usePaginationFragment } from "react-relay";

import { ActivityIndicator } from "../../../components/shared/ActivityIndicator";
import { FillWidthLoading } from "../../../components/shared/FillWidthLoading";
import { ExtractNodeFromConnection } from "../../../utility-types";
import { useTranslation } from "../../../utility/i18n/translation";
import { Icons } from "../../../utility/icons";
import { InfiniteList } from "../../components/InfiniteList";
import { skeletonify_multiple } from "../../skeleton";

import {
  SearchEquipmentDialogLocationFilter,
  SearchEquipmentDialogLocationFilterProps,
} from "./SearchEquipmentDialogLocationFilter";
import { SearchEquipmentDialogRow, SearchEquipmentDialogRowProps } from "./SearchEquipmentDialogRow";
import { SearchEquipmentDialogListRefetchQuery } from "./__generated__/SearchEquipmentDialogListRefetchQuery.graphql";
import {
  SearchEquipmentDialogList_plant$data,
  SearchEquipmentDialogList_plant$key,
} from "./__generated__/SearchEquipmentDialogList_plant.graphql";

type Item = ExtractNodeFromConnection<SearchEquipmentDialogList_plant$data["equipmentSearch"]>;

const ActualComponent = (
  props: {
    plant?: SearchEquipmentDialogList_plant$key | null;
    searchTerm: string;
    debouncedOnChange: DebouncedFunc<(newValue: string) => void>;
  } & Pick<SearchEquipmentDialogLocationFilterProps, "onLocationChanged" | "selectedLocation"> &
    Pick<SearchEquipmentDialogRowProps, "onEquipmentPressed">,
) => {
  const { translate } = useTranslation();
  const {
    plant: plantRef,
    debouncedOnChange,
    searchTerm,
    onLocationChanged,
    selectedLocation,
    onEquipmentPressed,
  } = props;

  const { data, ...pagination } = usePaginationFragment(
    graphql`
      fragment SearchEquipmentDialogList_plant on Plant
      @refetchable(queryName: "SearchEquipmentDialogListRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 20 }
        after: { type: "ID" }
        equipmentSearchInput: { type: "PlantEquipmentSearchInput!" }
      ) {
        ...SearchEquipmentDialogLocationFilter_plant
        equipmentSearch(first: $first, after: $after, input: $equipmentSearchInput)
          @connection(key: "SearchEquipmentDialogList_equipmentSearch") {
          edges {
            node {
              id
              ...SearchEquipmentDialogRow_equipment
            }
          }
        }
      }
    `,
    plantRef ?? null,
  );

  const emptyLabel = translate("CREATE_TICKET.ERROR.SEARCH_DIALOG.NO_ITEMS", "No equipment found");

  const errorLabel = translate("CREATE_TICKET.ERROR.SEARCH_DIALOG.ERROR", "Could not fetch equipment");

  const itemKeyExtractor = useCallback((item: Item) => item.id, []);

  const itemRender = useCallback(
    (item: Item) => <SearchEquipmentDialogRow onEquipmentPressed={onEquipmentPressed} equipment={item} />,
    [onEquipmentPressed],
  );

  const handleClear = useCallback(() => debouncedOnChange(""), [debouncedOnChange]);

  const onChange = useCallback<NonNullable<TextFieldProps["onChange"]>>(
    (event) => {
      debouncedOnChange(event.target.value);
    },
    [debouncedOnChange],
  );

  const isSearching = useEquipmentSearchIsLoading(pagination.refetch, searchTerm, selectedLocation?.id);

  const filtered = data?.equipmentSearch?.edges.filter(hasValue).map(({ node }) => node);

  return {
    searchInput: <SearchTextField loading={isSearching} onChange={onChange} handleClear={handleClear} />,
    list:
      data?.equipmentSearch === undefined ? (
        <FillWidthLoading />
      ) : (
        <InfiniteList<Item>
          {...pagination}
          emptyLabel={emptyLabel}
          errorLabel={errorLabel}
          items={filtered}
          itemKeyExtractor={itemKeyExtractor}
          itemRender={itemRender}
          itemSpacing={1}
        />
      ),
    locationSearch: (
      <SearchEquipmentDialogLocationFilter
        plant={data}
        onLocationChanged={onLocationChanged}
        selectedLocation={selectedLocation}
      />
    ),
  };
};

const SearchTextField: FC<{ loading: boolean; handleClear?: () => void } & TextFieldProps> = ({
  onChange,
  loading,
  handleClear,
}) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();
  const [internalValue, setInternalValue] = useState("");

  const onChangeText = useCallback<NonNullable<TextFieldProps["onChange"]>>(
    (event) => {
      const { value } = event.target;
      setInternalValue(value);
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  const onClear = useCallback(() => {
    setInternalValue("");
    if (handleClear) {
      handleClear();
    }
  }, [handleClear]);

  return (
    <TextField
      onChange={onChangeText}
      value={internalValue}
      placeholder={translate("CREATE_TICKET.ERROR.SEARCH_DIALOG.PLACEHOLDER", "Search")}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icons.Search fill={palette.text.disabled} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {loading ? <ActivityIndicator size={24} /> : <Box width={24} />}
            <IconButton onClick={onClear}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const SkeletonComponent = () => ({
  searchInput: <SearchTextField loading />,
  list: <FillWidthLoading />,
  locationSearch: <SearchEquipmentDialogLocationFilter.Skeleton />,
});

const StructureComponent = (props: { searchInput: JSX.Element; list: JSX.Element; locationSearch: JSX.Element }) => {
  const { list, locationSearch, searchInput } = props;
  const { translate } = useTranslation();

  return (
    <Grid2 sx={{ mx: 3 }}>
      <Grid2 container flexDirection={"column"} spacing={3}>
        <Grid2>{searchInput}</Grid2>
        <Grid2 container flexDirection={"column"} spacing={1}>
          <Grid2>
            <Typography>
              {translate("CREATE_TICKET.ERROR.SEARCH_DIALOG.LOCATION_HEADER", "Location (Hall/Section)")}
            </Typography>
          </Grid2>
          <Grid2>{locationSearch}</Grid2>
        </Grid2>
        <Grid2>
          <Typography variant="subtitle2">
            {translate("CREATE_TICKET.ERROR.SEARCH_DIALOG.RESULTS_HEADER", "Search results")}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 mt={2} style={{ overflow: "auto", height: "50vh" }}>
        {list}
      </Grid2>
    </Grid2>
  );
};

export const SearchEquipmentDialogList = skeletonify_multiple(
  "SearchEquipmentDialogList",
  ActualComponent,
  SkeletonComponent,
  StructureComponent,
);

const useEquipmentSearchIsLoading = (
  refetch: RefetchFnDynamic<SearchEquipmentDialogListRefetchQuery, null>,
  searchTerm: string,
  locationId?: string,
): boolean => {
  const fetchRef = useRef<Disposable>(undefined);

  const [isInFlight, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      fetchRef.current?.dispose();
      fetchRef.current = refetch(
        {
          equipmentSearchInput: {
            searchTerm,
            locationId,
          },
        },
        { fetchPolicy: "store-and-network" },
      );
    });
  }, [locationId, refetch, searchTerm]);

  return isInFlight;
};
