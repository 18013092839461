import { hasValue } from "@lego/mst-error-utilities";
import { Card, Container, Grid2, Typography, useTheme } from "@mui/material";
import { FC, ReactElement, useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router";

import { FillWidthLoading } from "./components/shared/FillWidthLoading";
import { ProfileContainer } from "./containers";
import { AddMachineHoursToTicket } from "./containers/AddMachineHoursToTicket";
import { AddRepairDocumentation } from "./containers/AddRepairDocumentation";
import { AddTimeToTicket } from "./containers/AddTimeToTicket";
import { AdminContainer } from "./containers/AdminContainer";
import { CloseTicketContainer } from "./containers/CloseTicketContainer";
import { ImageSearchContainer } from "./containers/ImageSearchContainer";
import { LoginContainer } from "./containers/LoginContainer";
import { useAuthContext } from "./contexts/AuthContext";
import { SparepartSearchContainer } from "./migration/components/sparepart-list/SparepartSearchContainer";
import { CmsCreateTicket } from "./migration/create-cms-ticket/CmsCreateTicket";
import { CreateTicketContainer } from "./migration/create-ticket/CreateTicket";
import { DashboardWrapper } from "./migration/dashboard/DashboardWrapper";
import { EquipmentDetails } from "./migration/equipment-details/EquipmentDetails";
import { EquipmentSearchContainer } from "./migration/equipment-list/EquipmentSearchContainer";
import { PlannedJobsContainer } from "./migration/planned-jobs-list/PlannedJobsContainer";
import { TicketDetails } from "./migration/ticket-details/TicketDetails";
import { TicketSearch } from "./migration/ticket-search/TicketSearch";
import { Figures } from "./utility/figures";
import { useTranslation } from "./utility/i18n/translation";

const routes = {
  root: "/",
  login: "/login",
  imageSearch: "/image-search",
  profile: "/profile",
  admin: "/admin",
  hallMonitors: {
    path: "/hall-monitors",
  },
  sparepart: {
    search: "/sparepart/search",
  },
  equipment: {
    path: "/equipment",
    search: "/equipment/search",
    details: "/equipment/:id/info/:tab",
  },
  createTicket: {
    path: "/ticket/create",
  },
  createCmsTicket: {
    path: "/cms-ticket/create",
  },
  tickets: {
    path: "/tickets",
    id: "/tickets/:id",
    withTab: "/tickets/:id/info/:tab",
    repair: {
      suffix: "/add-repair-documentation",
      path: `/tickets/:id/add-repair-documentation`,
    },
    addTime: {
      suffix: "/add-time",
      path: `/tickets/:id/add-time`,
    },
    addMachineTime: {
      suffix: "/add-machine-time",
      path: `/tickets/:id/add-machine-time`,
    },
    close: {
      path: `/tickets/:id/close/:step`,
      suffix: "/close/",
    },
  },
  plannedJobs: "/planned-jobs",
};

export const AppRoutes = {
  ...routes,
  createTicket: (equipmentId?: string | undefined): string => {
    if (hasValue(equipmentId)) {
      return `${routes.createTicket.path}?equipmentId=${equipmentId}`;
    }
    return `${routes.createTicket.path}`;
  },
  createCmsTicket: (equipmentId?: string | undefined): string => {
    if (hasValue(equipmentId)) {
      return `${routes.createCmsTicket.path}?equipmentId=${equipmentId}`;
    }
    return `${routes.createCmsTicket.path}`;
  },
  tickets: {
    list: routes.tickets.path,
    byTicketNumber: (ticketNumber: string | number, tab: RouteTypes["ticket"]["tab"]): string => {
      return `${routes.tickets.path}/${ticketNumber}/info/${tab}`;
    },
    repair: (ticketId: string): string => `${routes.tickets.path}/${ticketId}${routes.tickets.repair.suffix}`,
    manHours: (ticketId: string): string => `${routes.tickets.path}/${ticketId}${routes.tickets.addTime.suffix}`,
    machineHours: (ticketId: string): string =>
      `${routes.tickets.path}/${ticketId}${routes.tickets.addMachineTime.suffix}`,
    closeTicket: (ticketId: string, closeStep: RouteTypes["close"]["step"]): string =>
      `${routes.tickets.path}/${ticketId}${routes.tickets.close.suffix}${closeStep}`,
  },
  equipment: {
    root: routes.equipment.path,
    search: routes.equipment.search,
    byId: (equipmentNumber: string, tab: RouteTypes["equipment"]["tab"]): string => {
      return `${routes.equipment.path}/${equipmentNumber}/info/${tab}`;
    },
  },
  sparepart: {
    search: routes.sparepart.search,
  },
  hallMonitorWithId: (id: string) => {
    return `${routes.hallMonitors.path}/${id}`;
  },
};

export const useRouteRootType = () => {
  const { pathname } = useLocation();
  return pathname.startsWith(AppRoutes.tickets.list) ? "ticket" : "equipment";
};
//type EquipmentOnlyTabs = SharedTabs | 'qa';

type SharedTabs =
  | "details"
  | "history"
  | "costs"
  | "documents"
  | "open-tickets"
  | "sister-moulds"
  | "mts-log"
  | "spareparts"
  | "qa";

export type RouteTypes = {
  close: {
    id: string;
    step: "repair" | "manhours" | "cleaning" | "spring" | "location" | "flow" | "machine-hours" | "gat";
  };
  ticket: {
    id: string;
    tab: SharedTabs;
  };
  equipment: {
    id: string;
    tab: SharedTabs;
  };
};

const useDocumentTitle = () => {
  const { translate } = useTranslation();

  useEffect(() => {
    const title = translate("NAVBAR.SITE_NAME", undefined);
    const isLocalHost = window.location.hostname.includes("localhost");
    const isUAT = window.location.hostname.includes("uat");

    if (isLocalHost) {
      document.title = title + " - localhost";
    }

    if (isUAT) {
      document.title = title + " - UAT";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const MainRouter: FC = () => {
  useDocumentTitle();
  const { isStartupInProgress } = useAuthContext();
  // While the startup of MSAL is in progress, the authentication state is always false, so we need to show a loading spinner
  // to avoid being thrown back to the root page on reload.
  return isStartupInProgress ? (
    <FillWidthLoading />
  ) : (
    <Routes>
      <Route path={routes.login} element={<LoginContainer />}></Route>

      {/* private routes */}
      <Route element={<PrivateRoute />}>
        <Route path={routes.root} element={<DashboardWrapper />} />
        <Route path={routes.profile} element={<ProfileContainer />} />
        <Route path={routes.createTicket.path} element={<CreateTicketContainer />} />

        <Route path={routes.imageSearch} element={<ImageSearchContainer />} />
        <Route path={routes.equipment.search} element={<EquipmentSearchContainer />} />
        <Route path={routes.equipment.details} element={<EquipmentDetails />} />
        <Route path={routes.tickets.id} element={<TicketDetails />} />
        <Route path={routes.tickets.withTab} element={<TicketDetails />} />
        <Route path={routes.sparepart.search} element={<SparepartSearchContainer />} />

        {/* repairman routes */}
        <Route element={<RepairmanRoute />}>
          <Route path={routes.tickets.path} element={<TicketSearch />} />
          <Route path={routes.tickets.close.path} element={<CloseTicketContainer />} />
          <Route path={routes.tickets.addTime.path} element={<AddTimeToTicket />} />
          <Route path={routes.tickets.addMachineTime.path} element={<AddMachineHoursToTicket />} />
          <Route path={routes.tickets.repair.path} element={<AddRepairDocumentation />} />
          <Route path={routes.createCmsTicket.path} element={<CmsCreateTicket />} />
        </Route>

        {/* admin routes */}
        <Route element={<AdminRoute />}>
          <Route path={routes.admin} element={<AdminContainer />} />
        </Route>

        <Route element={<PlannedJobsListUserRoute />}>
          <Route path={routes.plannedJobs} element={<PlannedJobsContainer />} />
        </Route>
      </Route>
    </Routes>
  );
};

const PrivateRoute: FC = () => {
  const { authenticated } = useAuthContext();

  return authenticated ? <Outlet /> : <Navigate to={AppRoutes.login} />;
};

const RepairmanRoute: FC = () => {
  const { isRepairman } = useAuthContext();

  return isRepairman ? <Outlet /> : <Navigate to={AppRoutes.root} />;
};

const PlannedJobsListUserRoute: FC = () => {
  const { isPlannedJobsListUser } = useAuthContext();

  return isPlannedJobsListUser ? <Outlet /> : <Navigate to={AppRoutes.root} />;
};

const AdminRoute: FC = () => {
  const { isAdmin } = useAuthContext();

  return isAdmin ? <Outlet /> : <Navigate to={AppRoutes.root} />;
};

export const RouteRequiringPermission: FC<{
  children: ReactElement;
  allowed: boolean;
}> = ({ children, allowed }) => {
  const { translate } = useTranslation();
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  if (allowed) {
    return <>{children}</>;
  } else {
    return (
      <Container style={{ marginTop: 100 }}>
        <Card>
          <Grid2
            container
            spacing={4}
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Grid2>
              <Typography align="center">
                {translate(
                  "PERMISSIONS.PERMISSION_MISSING_ROUTE",
                  "You do not have permissions to view this page. If you believe this is an error, please try logging out and back in. If the issue persists, contact a specialist.",
                )}
              </Typography>
            </Grid2>
            <Grid2
              container
              style={{
                flex: 1,
                margin: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid2>
                <Figures.ConstructionWorker fill={main} style={{ height: "100%", width: "100%" }} />
              </Grid2>
            </Grid2>
          </Grid2>
        </Card>
      </Container>
    );
  }
};
