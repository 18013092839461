import { Alert, Box, Grid2, Typography } from "@mui/material";
import { FC, JSX, useCallback } from "react";
import { usePaginationFragment } from "react-relay";
import { Waypoint } from "react-waypoint";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";
import { Icons } from "../../utility/icons";

import { BackToTop } from "./BackToTop";

const EmptyView: FC<{
  label: string;
}> = ({ label }) => {
  return (
    <Grid2 container justifyContent="center" alignItems="center" direction="column">
      <Grid2>
        <Icons.NoTickets fillOpacity={0.6} style={{ fontSize: 120 }} />
      </Grid2>
      <Grid2>
        <Typography>{label}</Typography>
      </Grid2>
    </Grid2>
  );
};

const ErrorView: FC<{
  label: string;
}> = ({ label }) => {
  return <Alert severity="error">{label}</Alert>;
};

type RelayPaginationProps = Pick<ReturnType<typeof usePaginationFragment>, "hasNext" | "isLoadingNext" | "loadNext">;
export type InfiniteListProps<T> = {
  items?: ReadonlyArray<T> | null;
  itemRender: (item: T, index: number) => JSX.Element;
  DividerComponent?: JSX.Element;
  itemKeyExtractor(item: T): string;
  errorLabel: string;
  emptyLabel: string;
  pageSize?: number;
  itemSpacing?: number;
  disableOverscrolling?: boolean;
} & RelayPaginationProps;

/**
 *
 * @returns A list wrapper that shows empty/error views for a list, as well as handle infinite scroll loading
 */
export const InfiniteList = <T,>(props: InfiniteListProps<T>): JSX.Element => {
  const {
    hasNext,
    isLoadingNext,
    loadNext,
    items,
    emptyLabel,
    errorLabel,
    itemKeyExtractor,
    itemRender,
    DividerComponent,
    pageSize = 10,
    disableOverscrolling = false,
    itemSpacing = 2,
  } = props;
  const loadNextPage = useCallback(() => {
    loadNext(pageSize);
  }, [loadNext, pageSize]);

  if (!items) {
    return <ErrorView label={errorLabel} />;
  }

  if (items.length === 0) {
    return <EmptyView label={emptyLabel} />;
  }

  return (
    <Grid2 container direction="column" spacing={itemSpacing} flexWrap="nowrap">
      {items.map((item, index) => (
        <Grid2 key={itemKeyExtractor(item)}>
          {index > 0 && DividerComponent}
          {itemRender(item, index)}
        </Grid2>
      ))}
      {hasNext && !isLoadingNext && <Waypoint onEnter={loadNextPage} />}
      {isLoadingNext && (
        <Grid2 size={{ xs: "grow" }} alignSelf="center">
          <ActivityIndicator />
        </Grid2>
      )}
      {disableOverscrolling ? null : (
        <Grid2>
          <Box height={80} />
        </Grid2>
      )}
      <BackToTop />
    </Grid2>
  );
};
