import { Grid2, Typography } from "@mui/material";
import { FC, JSX } from "react";

export const MtsLogCardDataRow: FC<{
  title: string;
  children: JSX.Element;
}> = ({ title, children }) => {
  return (
    <Grid2 container direction="row" size={{ xs: "grow" }} maxHeight={35}>
      <Grid2 size={{ xs: "grow" }}>
        <Typography>{title}:</Typography>
      </Grid2>
      <Grid2 size={{ xs: "grow" }}>{children}</Grid2>
    </Grid2>
  );
};
