import { Card, CardActionArea, Grid2, IconButton, Skeleton, Tooltip } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, JSX } from "react";
import { useFragment } from "react-relay";
import { NavLink } from "react-router";

import { useAreaAndProcessContext } from "../../contexts/area";
import { AppRoutes } from "../../Router";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { CleaningPercentageChip } from "../components/equipment/CleaningPercentageChip";
import { DimmingsCountChip } from "../components/equipment/DimmingsCountChip";
import { EquipmentDescription } from "../components/equipment/EquipmentDescription";
import { EquipmentGroupChip } from "../components/equipment/EquipmentGroupChip";
import { EquipmentLocation } from "../components/equipment/EquipmentLocation";
import { EquipmentNumber } from "../components/equipment/EquipmentNumber";
import { skeletonify, skeletonify_multiple } from "../skeleton";

import { EquipmentCard_Chips_equipment$key } from "./__generated__/EquipmentCard_Chips_equipment.graphql";
import { EquipmentCard_equipment$key } from "./__generated__/EquipmentCard_equipment.graphql";
import { EquipmentCard_EquipmentOnOtherPlantWarning_equipment$key } from "./__generated__/EquipmentCard_EquipmentOnOtherPlantWarning_equipment.graphql";

const ActualComponent = (props: { mould: EquipmentCard_Chips_equipment$key }) => {
  const { mould: mouldRef } = props;
  const data = useFragment(
    graphql`
      fragment EquipmentCard_Chips_equipment on Equipment {
        ...DimmingsCountChip_equipment
        ...CleaningPercentageChip_equipment
        ...EquipmentGroupChip_equipment
      }
    `,
    mouldRef,
  );

  return [
    <EquipmentGroupChip key="EquipmentGroupChip" equipment={data} />,
    <DimmingsCountChip key="DimmingsCountEquipment" equipment={data} />,
    <CleaningPercentageChip key="CleaningPercentageEquipment" equipment={data} />,
  ];
};

const SkeletonComponent = () => [
  <EquipmentGroupChip.Skeleton key="EquipmentGroupChip" />,
  <DimmingsCountChip.Skeleton key="DimmingsCountEquipment" />,
  <CleaningPercentageChip.Skeleton key="CleaningPercentageEquipment" />,
];

const StructureComponent = ({ children }: { children: JSX.Element[] }) => (
  <Grid2 container spacing={2} direction="row" alignItems="center">
    {children.map((c) => (
      <Grid2 key={c.key}>{c}</Grid2>
    ))}
  </Grid2>
);

const Chips = skeletonify("EquipmentCard_Chips_mould", ActualComponent, SkeletonComponent, StructureComponent);

const EquipmentOnOtherPlantWarningActual: FC<{
  plant: EquipmentCard_EquipmentOnOtherPlantWarning_equipment$key;
}> = (props) => {
  const { plant: plantRef } = props;
  const { selectedArea } = useAreaAndProcessContext();
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment EquipmentCard_EquipmentOnOtherPlantWarning_equipment on Equipment {
        plant {
          plantNumber
        }
      }
    `,
    plantRef,
  );

  const plantId = data.plant?.plantNumber.toString();
  if (plantId === selectedArea.plantId) {
    return null;
  }

  return (
    <Tooltip
      data-cy="EquipmentOnOtherPlantWarning"
      title={translate("EQUIPMENT_CARD.ON_OTHER_PLANT_WARNING_TOOLTIP", "Equipment located on plant {{ plantId }}", {
        plantId,
      })}
    >
      <IconButton>
        <Icons.WarningTriangle color="error" />
      </IconButton>
    </Tooltip>
  );
};

const EquipmentOnOtherPlantWarningSkeleton: FC = () => {
  return <Skeleton variant="rectangular" />;
};

const EquipmentOnOtherPlantWarning = skeletonify(
  "EquipmentOnOtherPlantWarning",
  EquipmentOnOtherPlantWarningActual,
  EquipmentOnOtherPlantWarningSkeleton,
);

const EquipmentCardActual = (props: { equipment: EquipmentCard_equipment$key }) => {
  const { equipment: equipmentRef } = props;
  const equipment = useFragment(
    graphql`
      fragment EquipmentCard_equipment on Equipment {
        equipmentNumber
        ...EquipmentNumber_equipment
        ...EquipmentDescription_equipment
        ...EquipmentLocation_equipment #@defer
        ...EquipmentCard_Chips_equipment #@defer
        ...EquipmentCard_EquipmentOnOtherPlantWarning_equipment
      }
    `,
    equipmentRef,
  );

  return {
    routeTo: AppRoutes.equipment.byId(equipment.equipmentNumber.toString(), "details"),
    equipmentNumber: equipment.equipmentNumber,
    equipmentNumberComponent: <EquipmentNumber equipment={equipment} />,
    equipmentDescription: <EquipmentDescription equipment={equipment} />,
    equipmentLocation: <EquipmentLocation.Suspense equipment={equipment} />,
    chips: <Chips.Suspense mould={equipment} />,
    equipmentOnOtherPlantWarning: <EquipmentOnOtherPlantWarning.Suspense plant={equipment} />,
  };
};

const EquipmentCardSkeleton = () => ({
  equipmentNumberComponent: <EquipmentNumber.Skeleton />,
  equipmentDescription: <EquipmentDescription.Skeleton />,
  equipmentLocation: <EquipmentLocation.Skeleton />,
  chips: <Chips.Skeleton />,
  equipmentOnOtherPlantWarning: <EquipmentOnOtherPlantWarning.Skeleton />,
});

const EquipmentCardStructure: FC<{
  routeTo?: string;
  equipmentNumber?: number;
  equipmentNumberComponent: JSX.Element;
  equipmentDescription: JSX.Element;
  equipmentLocation: JSX.Element;
  chips: JSX.Element;
  equipmentOnOtherPlantWarning: JSX.Element;
}> = (props) => {
  const {
    routeTo,
    equipmentNumber,
    equipmentNumberComponent,
    equipmentDescription,
    equipmentLocation,
    chips,
    equipmentOnOtherPlantWarning,
  } = props;
  return (
    <Card sx={{ flex: 1, p: 0 }} data-cy={`EquipmentCard:${equipmentNumber}`}>
      <CardActionArea component={NavLink} to={routeTo ?? ""} disabled={!routeTo} sx={{ p: 2 }}>
        <Grid2 container spacing={1}>
          <Grid2 container direction="column" spacing={1} size={{ xs: 12 }}>
            <Grid2>{equipmentNumberComponent}</Grid2>
            <Grid2>{equipmentDescription}</Grid2>
          </Grid2>
          <Grid2 container direction="row" justifyContent="space-between" size={{ xs: 12 }}>
            <Grid2>{chips}</Grid2>
            <Grid2>
              <Grid2 container direction="row" spacing={1} alignItems="center">
                <Grid2>{equipmentOnOtherPlantWarning}</Grid2>
                <Grid2>{equipmentLocation}</Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </CardActionArea>
    </Card>
  );
};

export const EquipmentCard = skeletonify_multiple(
  "EquipmentCard",
  EquipmentCardActual,
  EquipmentCardSkeleton,
  EquipmentCardStructure,
);
