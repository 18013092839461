import { hasValue } from "@lego/mst-error-utilities";
import { Grid2 } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { ComponentProps, FC, isValidElement } from "react";
import { useFragment } from "react-relay";

import { CardWithTitle, CardWithTitleSkeleton } from "../../../components/shared/CardWithTitle";
import { TextWithLabel, TextWithLabelSkeleton } from "../../../components/shared/TextWithLabel";
import { useFormatRelayLocalTimestampWithLocale } from "../../../utility/date";
import { useTranslation } from "../../../utility/i18n/translation";
import { skeletonify } from "../../skeleton";
import { FullWidthGrid } from "../../ticket-details/TicketDetailsPane";

import { ManufacturerWidget_equipment$key } from "./__generated__/ManufacturerWidget_equipment.graphql";

const ActualComponent: FC<{
  equipment: ManufacturerWidget_equipment$key | null | undefined;
}> = ({ equipment: equipmentRef }) => {
  const { translate } = useTranslation();
  const { parseTimestamp } = useFormatRelayLocalTimestampWithLocale();

  const data = useFragment(
    graphql`
      fragment ManufacturerWidget_equipment on Equipment {
        manufacturer
        countryOfManufacture
        modelNumber
        constructionDate {
          date
          time
        }
        acquisitionDate {
          date
          time
        }
      }
    `,
    equipmentRef,
  );

  const allDataIsNull =
    !data?.acquisitionDate &&
    !data?.constructionDate &&
    !data?.countryOfManufacture &&
    !data?.manufacturer &&
    !data?.modelNumber;

  if (!data || !equipmentRef || allDataIsNull) {
    return null;
  }

  const { acquisitionDate, constructionDate, countryOfManufacture, manufacturer, modelNumber } = data;

  return (
    <CardWithTitle title={translate("EQUIPMENT_WIDGETS.MANUFACTURER.TITLE", "Manufacturer")}>
      <Grid2 container direction="row" justifyContent="start" maxHeight={230}>
        <Grid2 size={{ xs: 6 }}>
          <Grid2 container direction="column" spacing={2}>
            {hasValue(manufacturer) && (
              <ManufacturerItem
                text={manufacturer}
                label={translate("EQUIPMENT_WIDGETS.MANUFACTURER.LABELS.MANUFACTURER", "Manufacturer")}
              />
            )}
            {hasValue(countryOfManufacture) && (
              <ManufacturerItem
                text={countryOfManufacture}
                label={translate(
                  "EQUIPMENT_WIDGETS.MANUFACTURER.LABELS.MANUFACTURING_COUNTRY",
                  "Manufacturing country",
                )}
              />
            )}
            {hasValue(constructionDate) && (
              <ManufacturerItem
                text={parseTimestamp({
                  ...constructionDate,
                  dateFormat: "yyy / MM ",
                })}
                label={translate(
                  "EQUIPMENT_WIDGETS.MANUFACTURER.LABELS.CONSTRUCTION_DATE",
                  "Construction year / month",
                )}
              />
            )}
          </Grid2>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Grid2 container direction="column" justifyContent="space-between" spacing={2}>
            {hasValue(modelNumber) && (
              <ManufacturerItem
                text={modelNumber}
                label={translate("EQUIPMENT_WIDGETS.MANUFACTURER.LABELS.MODEL_NO", "Model no")}
              />
            )}
            {hasValue(acquisitionDate) && (
              <ManufacturerItem
                text={parseTimestamp({
                  date: acquisitionDate.date,
                  time: acquisitionDate.time,
                  dateFormat: "PP",
                })}
                label={translate("EQUIPMENT_WIDGETS.MANUFACTURER.LABELS.ACQUISITION_DATE", "Acquisition date")}
              />
            )}
          </Grid2>
        </Grid2>
      </Grid2>
    </CardWithTitle>
  );
};

const ManufacturerItem: FC<ComponentProps<typeof TextWithLabel>> = (props) => {
  return (
    <Grid2>
      <TextWithLabel {...props} />
    </Grid2>
  );
};

const ManufacturerWidgetSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton>
      <Grid2 container direction="row" justifyContent="start">
        <Grid2 size={{ xs: "grow" }}>
          <Grid2 container direction="column" spacing={2}>
            <Grid2>
              <TextWithLabelSkeleton />
            </Grid2>
            <Grid2>
              <TextWithLabelSkeleton />
            </Grid2>
            <Grid2>
              <TextWithLabelSkeleton />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={{ xs: "grow" }}>
          <Grid2 container direction="column" justifyContent="space-between" spacing={2}>
            <Grid2>
              <TextWithLabelSkeleton />
            </Grid2>
            <Grid2>
              <TextWithLabelSkeleton />
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </CardWithTitleSkeleton>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StructureComponent = ({ children }: { children: any }) => {
  if (!isValidElement(children)) {
    return null;
  }
  return <FullWidthGrid>{children}</FullWidthGrid>;
};

export const ManufacturerWidget = skeletonify(
  "ManufacturerWidget",
  ActualComponent,
  ManufacturerWidgetSkeleton,
  StructureComponent,
);
