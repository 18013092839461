import { Grid2, Typography, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { DurationUnit, formatDuration } from "date-fns";
import * as duration from "duration-fns";
import { FC } from "react";
import { useFragment } from "react-relay";

import { FillWidthLoading } from "../../../components/shared/FillWidthLoading";
import { localeToDateFnsLocale } from "../../../utility/i18n/i18n";
import { useTranslation } from "../../../utility/i18n/translation";
import { BomSparePartRow } from "../../dashboard/spare-part-bom/BomSparePartRow";
import { skeletonify } from "../../skeleton";

import { SparePartDetailsDialogPartInfo_sparePart$key } from "./__generated__/SparePartDetailsDialogPartInfo_sparePart.graphql";

const ActualComponent: FC<{
  sparePart?: SparePartDetailsDialogPartInfo_sparePart$key | null;
}> = (props) => {
  const { sparePart: sparePartRef } = props;
  const { translate, locale } = useTranslation();
  const data = useFragment(
    graphql`
      fragment SparePartDetailsDialogPartInfo_sparePart on SparePart @argumentDefinitions(processId: { type: "ID!" }) {
        newSparePart {
          sparePartNumber
        }
        dimensions
        unitOfMeasure
        procurementInfo(input: { processId: $processId }) {
          vendorMaterial
          vendor {
            id
            name
          }
          manufacturer {
            id
            name
          }
          manufacturingPart
          price {
            currency
            value
          }
          leadTime
        }
        ...BomSparePartRow_sparePart @arguments(processId: $processId)
      }
    `,
    sparePartRef ?? null,
  );

  if (!data) {
    return null;
  }

  const { leadTime, vendor, vendorMaterial, manufacturer, manufacturingPart, price } = data.procurementInfo ?? {};

  const leadTimeDuration = leadTime ? duration.parse(leadTime) : undefined;

  return (
    <Grid2 container>
      {data && (
        <Grid2 container size={{ xs: 12 }}>
          <BomSparePartRow sparePart={data} hideRightItem disabled />
        </Grid2>
      )}
      <RelatedInfoFieldsContainer>
        <SparePartInfoWithLabel
          label={translate("SPAREPART_DETAILS.INFO_LABELS.SPAREPART_NUMBER", "New spare part number")}
          value={data?.newSparePart?.sparePartNumber.toString() ?? "--"}
        />
        <SparePartInfoWithLabel
          label={translate("SPAREPART_DETAILS.INFO_LABELS.SIZE", "Size / dimensions")}
          value={data?.dimensions ?? "--"}
        />
        <SparePartInfoWithLabel
          label={translate("SPAREPART_DETAILS.INFO_LABELS.UNIT_OF_MEASURE", "Unit of measure")}
          value={data?.unitOfMeasure ?? "--"}
        />
      </RelatedInfoFieldsContainer>
      <RelatedInfoFieldsContainer>
        <SparePartInfoWithLabel
          label={translate("SPAREPART_DETAILS.INFO_LABELS.VENDOR", "Vendor")}
          value={vendor ? `${vendor.id} - ${vendor.name}` : "--"}
        />
        <SparePartInfoWithLabel
          label={translate("SPAREPART_DETAILS.INFO_LABELS.VENDOR_MATERIAL", "Vendor material number")}
          value={vendorMaterial ?? "--"}
        />
        <SparePartInfoWithLabel
          label={translate("SPAREPART_DETAILS.INFO_LABELS.PRICE", "Price")}
          value={price?.value && price?.currency ? `${price.value} ${price.currency}` : "--"}
        />
        <SparePartInfoWithLabel
          label={translate("SPAREPART_DETAILS.INFO_LABELS.LEAD_TIME", "Delivery time")}
          value={
            leadTimeDuration && Object.values(leadTimeDuration).some((v) => v > 0)
              ? formatDuration(leadTimeDuration, {
                  // Hack to remove milliseconds from the list of units, because it's not present in the Duration type of date-fns, but it's in the one from duration-fns
                  format: duration.UNITS.filter(
                    (unit) => unit !== "milliseconds" && leadTimeDuration[unit] > 0,
                  ) as DurationUnit[],
                  zero: true,
                  locale: localeToDateFnsLocale(locale),
                })
              : "--"
          }
        />
      </RelatedInfoFieldsContainer>
      <RelatedInfoFieldsContainer>
        <SparePartInfoWithLabel
          label={translate("SPAREPART_DETAILS.INFO_LABELS.MANUFACTURER", "Manufacturer")}
          value={manufacturer ? `${manufacturer.id} - ${manufacturer.name}` : "--"}
        />
        <SparePartInfoWithLabel
          label={translate("SPAREPART_DETAILS.INFO_LABELS.MANUFACTURER_PART", "Manufacturing part number")}
          value={manufacturingPart ?? "--"}
        />
      </RelatedInfoFieldsContainer>
    </Grid2>
  );
};

const SkeletonComponent: FC = () => {
  return <FillWidthLoading />;
};

export const SparePartDetailsDialogPartInfo = skeletonify(
  "SparePartDetailsDialogPartInfo",
  ActualComponent,
  SkeletonComponent,
);

const SparePartInfoWithLabel: FC<{ value: string; label: string }> = ({ label, value }) => {
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme();
  return (
    <Grid2 container flexDirection="column" my={2}>
      <Grid2>
        <Typography>{value}</Typography>
      </Grid2>
      <Grid2>
        <Typography color={secondary} variant="caption">
          {label}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RelatedInfoFieldsContainer: FC<{ children: any }> = ({ children }) => {
  return (
    <Grid2 container size={{ xs: 12, md: 6, lg: 4 }} flexDirection={"column"}>
      {children}
    </Grid2>
  );
};
