import { hasValue } from "@lego/mst-error-utilities";
import { Card, CardActionArea, CardContent, Grid2 } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, ReactElement } from "react";
import { useFragment } from "react-relay";
import { NavLink } from "react-router";

import { TicketTypePill } from "../../components/shared/TicketTypePill";
import { ChipWithDefaultBackground } from "../../components/ticket-card/components/TicketCardMouldChips";
import { AppRoutes } from "../../Router";
import { Icons } from "../../utility/icons";
import { EquipmentLocation } from "../components/equipment/EquipmentLocation";
import { PriorityPill } from "../components/ticket/PriorityPill";
import { RequiredDates } from "../components/ticket/RequiredDates";
import { TicketNumber } from "../components/ticket/TicketNumber";
import { skeletonify_multiple } from "../skeleton";

import {
  EquipmentHistoryCard_ticket$data,
  EquipmentHistoryCard_ticket$key,
} from "./__generated__/EquipmentHistoryCard_ticket.graphql";
import { useLabels_ticket$key } from "./__generated__/useLabels_ticket.graphql";
import { EquipmentHistoryCardDescription } from "./EquipmentHistoryCardDescription";
import { EquipmentHistoryCardRepairDocumentation } from "./EquipmentHistoryCardRepairDocumentation";
import { useLabels } from "./useLabels";

type StructureComponentProps = {
  routeTo?: string;
  priority: ReactElement;
  ticketNumber: ReactElement;
  description: ReactElement;
  closedDate: ReactElement;
  equipmentLocation?: ReactElement;
  repairDocumentation?: ReactElement;
  chips: ReactElement;
  ticket?: EquipmentHistoryCard_ticket$data;
};

const ActualComponent = (props: { ticket: EquipmentHistoryCard_ticket$key }) => {
  const { ticket: ticketRef } = props;

  const ticket = useFragment(
    graphql`
      fragment EquipmentHistoryCard_ticket on Ticket {
        __typename
        ticketNumber
        equipment {
          ...EquipmentLocation_equipment
        }
        repairDocumentation {
          ...EquipmentHistoryCardRepairDocumentation_repairDocumentation
        }
        ...useLabels_ticket
        ...PriorityPill_ticket
        ...TicketNumber_ticket
        ...RequiredDates_ticket
        ...EquipmentHistoryCardDescription_ticket
        ...TicketTypePill_ticket
      }
    `,
    ticketRef,
  );

  return {
    ticket,
    routeTo: ticket.ticketNumber ? `${AppRoutes.tickets.byTicketNumber(ticket.ticketNumber, "details")}` : undefined,
    priority: <PriorityPill ticket={ticket} />,
    ticketNumber: <TicketNumber ticket={ticket} />,
    description: <EquipmentHistoryCardDescription ticket={ticket} />,
    closedDate: <RequiredDates ticket={ticket} />,
    equipmentLocation: ticket.equipment ? <EquipmentLocation equipment={ticket.equipment} /> : undefined,
    repairDocumentation: ticket.repairDocumentation ? (
      <EquipmentHistoryCardRepairDocumentation repairDocumentation={ticket.repairDocumentation} />
    ) : undefined,
    chips: <Chips ticket={ticket} />,
  };
};

const SkeletonComponent = () => ({
  priority: <PriorityPill.Skeleton />,
  ticketNumber: <TicketNumber.Skeleton />,
  description: <EquipmentHistoryCardDescription.Skeleton />,
  closedDate: <RequiredDates.Skeleton />,
  equipmentLocation: <EquipmentLocation.Skeleton />,
  repairDocumentation: <EquipmentHistoryCardRepairDocumentation.Skeleton />,
  chips: <></>,
});

const StructureComponent = (props: StructureComponentProps) => {
  const {
    ticket,
    routeTo,
    priority,
    ticketNumber,
    description,
    closedDate,
    equipmentLocation,
    repairDocumentation,
    chips,
  } = props;

  return (
    <Card sx={{ flex: 1, p: 0 }}>
      <CardActionArea
        component={NavLink}
        to={routeTo ?? ""}
        disabled={!hasValue(routeTo)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Grid2 container direction="row" sx={{ p: 2 }}>
          {/* Prio pill and ticket type label */}
          <Grid2 container direction="column" size={{ xs: 1 }} sx={{ mr: 1 }}>
            <Grid2 size={{ xs: 6 }} display="flex" flexDirection="column" alignItems="center">
              {priority}
            </Grid2>
          </Grid2>

          {/* Main content (descriptions, ids) */}
          <Grid2 size={{ xs: "grow" }}>
            <Grid2 container direction="column" spacing={2} alignItems="flex-start">
              <Grid2>{ticketNumber}</Grid2>
              <Grid2>{description}</Grid2>
            </Grid2>
          </Grid2>

          {/* Date and place */}
          <Grid2 size={{ xs: 3 }}>
            <Grid2
              container
              direction="column"
              alignItems="flex-end"
              justifyContent="space-between"
              spacing={2}
              sx={{ height: "100%" }}
            >
              <Grid2>
                <Grid2 container spacing={1}>
                  <Grid2>{ticket && <TicketTypePill ticket={ticket} />}</Grid2>
                  <Grid2>{closedDate}</Grid2>
                </Grid2>
              </Grid2>
              {hasValue(equipmentLocation) && <Grid2>{equipmentLocation}</Grid2>}
            </Grid2>
          </Grid2>
        </Grid2>
      </CardActionArea>
      <CardContent>
        <Grid2 container direction="row">
          <Grid2 size={{ xs: 1 }} />
          <Grid2 container direction="column" spacing={2} size={{ xs: "grow" }}>
            {hasValue(repairDocumentation) && <Grid2>{repairDocumentation}</Grid2>}
            <Grid2>{chips}</Grid2>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

const Chips: FC<{ ticket: useLabels_ticket$key }> = ({ ticket }) => {
  const { sparePartLabel, timeLabel, costLabel } = useLabels(ticket);

  return (
    <Grid2 container spacing={1}>
      <Grid2>{costLabel && <ChipWithDefaultBackground icon={<Icons.MoneyBag />} label={costLabel} />}</Grid2>
      <Grid2>{timeLabel && <ChipWithDefaultBackground icon={<Icons.Calendar />} label={timeLabel} />}</Grid2>
      <Grid2>
        {sparePartLabel && <ChipWithDefaultBackground icon={<Icons.SparePartCogs />} label={sparePartLabel} />}
      </Grid2>
    </Grid2>
  );
};

export const EquipmentHistoryCard = skeletonify_multiple(
  "EquipmentHistoryCard",
  ActualComponent,
  SkeletonComponent,
  StructureComponent,
);
