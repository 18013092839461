import { Chip, Grid2, Typography } from "@mui/material";
import { FC } from "react";

import { RepairDocumentationDamageFragment } from "../../__apollo__/graphql";
import { CARD_DATE_FORMAT, useDateFromMiddlewareWithLocale } from "../../utility/date";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { CollapsibleWidget } from "../shared/CollapsibleWidget";
import { ImageRow } from "../shared/ImageRow";

export const CTRepairDocumentationRow: FC<RepairDocumentationDamageFragment & { padding?: number }> = ({
  activities,
  causes,
  comments,
  damageCodeText,
  images,
  padding,
}) => {
  const { translate } = useTranslation();

  return (
    <CollapsibleWidget componentWhenClosed={<RowHeader title={damageCodeText} />} padding={padding ?? 8}>
      <Grid2 container style={{ paddingLeft: 40, paddingRight: 40, marginBottom: 20 }} spacing={2}>
        <Grid2 container spacing={4}>
          <Grid2>
            <CausesOrActivitiesComponent
              title={translate("CLOSE_TICKET.REPAIR_STEP.ROW.CAUSES_HEADER", "Cause")}
              items={causes}
            />
          </Grid2>
          {activities.length > 0 && (
            <Grid2>
              <CausesOrActivitiesComponent
                title={translate("CLOSE_TICKET.REPAIR_STEP.ROW.ACTIVITIES_HEADER", "Activities")}
                items={activities}
              />
            </Grid2>
          )}
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <DescriptionComponent comments={comments} />
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <ImageRow images={images} />
        </Grid2>
      </Grid2>
    </CollapsibleWidget>
  );
};

const CausesOrActivitiesComponent: FC<{
  title: string;
  items: Array<{ description: string }>;
}> = ({ title, items }) => {
  return (
    <Grid2 container spacing={1} direction="column">
      <Grid2>
        <Typography variant="caption" color="textSecondary">
          {title}
        </Typography>
      </Grid2>
      <Grid2 container spacing={2}>
        {items.map((item, index) => {
          return (
            <Grid2 key={index}>
              <Chip variant="outlined" label={item.description} />
            </Grid2>
          );
        })}
      </Grid2>
    </Grid2>
  );
};

const DescriptionComponent: FC<{
  comments: RepairDocumentationDamageFragment["comments"];
}> = ({ comments }) => {
  const { translate } = useTranslation();
  const { format } = useDateFromMiddlewareWithLocale();

  return (
    <Grid2 container direction="column" spacing={1}>
      <Grid2>
        <Typography variant="caption" color="textSecondary">
          {translate("CLOSE_TICKET.REPAIR_STEP.ROW.DESCRIPTION_HEADER", "Description")}
        </Typography>
      </Grid2>
      <Grid2 container>
        {comments.length === 0 ? (
          <Grid2>
            <Typography color="textSecondary">
              {translate("CLOSE_TICKET.REPAIR_STEP.ROW.DESCRIPTION_EMPTY", "(no description)")}
            </Typography>
          </Grid2>
        ) : (
          comments.map((comment, index) => {
            return (
              <Grid2 container key={`${index}:${comment}`} direction="column" size={{ xs: 12 }}>
                <Grid2>
                  <Typography>{comment.description}</Typography>
                </Grid2>
                <Grid2>
                  <Typography color="textSecondary" variant="caption">
                    {translate("CLOSE_TICKET.REPAIR_STEP.ROW.DESCRIPTION_CREATED_PREFIX", "Created {{date}}", {
                      date: format(comment.createdDate, CARD_DATE_FORMAT),
                    })}
                  </Typography>
                </Grid2>
              </Grid2>
            );
          })
        )}
      </Grid2>
    </Grid2>
  );
};

const RowHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <Grid2 container spacing={2} style={{ flex: 1 }}>
      <Grid2>
        <Icons.Hardhat />
      </Grid2>
      <Grid2>
        <Typography>{title}</Typography>
      </Grid2>
    </Grid2>
  );
};
